import { Picking } from './pages/picking/Picking'
import { Stocks } from './pages/stocks/Stocks'
import { Product } from './pages/stocks/Product'
import { Configurations } from './pages/configurations/Configurations'
import { Login } from './pages/Login'
import { Boxes } from './pages/boxes/Boxes'
import { Labels } from './pages/stocks/Labels'
import { Stats } from './pages/stats/Stats'
import { SyncRoutes } from './pages/routes/sync/SyncRoutes'
import { Expeditions } from './pages/expeditions/Expeditions'
import { Navigate } from 'react-router-dom'
import { RoutesSummary } from './pages/routes/summary/RoutesSummary'
import { UpdateDeliverySalesGroup } from './pages/deliveries/UpdateDeliverySalesGroup'
import { ExportAlternativeRoutes } from './pages/routes/export/ExportAlternativeRoutes'

export const RoutesMap = {
  protected: {
    home: {
      pathBuilder: () => '/',
      routeProps: {
        path: '/',
        element: <Navigate to='/picking' />
      }
    },
    picking: {
      pathBuilder: (id?: string) => id ? `/picking/${id}` : '/picking',
      routeProps: {
        exact: false,
        path: '/picking/*',
        element: <Picking />
      }
    },
    boxes: {
      pathBuilder: () => '/boxes',
      routeProps: {
        path: '/boxes',
        element: <Boxes />
      }
    },
    stats: {
      pathBuilder: () => '/stats',
      routeProps: {
        path: '/stats',
        element: <Stats />
      }
    },
    stocks: {
      pathBuilder: () => '/stocks',
      routeProps: {
        path: '/stocks',
        element: <Stocks />
      }
    },
    product: {
      pathBuilder: () => '/stocks/:productId',
      routeProps: {
        path: '/stocks/:productId',
        element: <Product />
      }
    },
    labels: {
      pathBuilder: () => '/stocks/labels',
      routeProps: {
        path: '/stocks/labels',
        element: <Labels />
      }
    },
    configurations: {
      pathBuilder: () => '/configurations',
      routeProps: {
        path: '/configurations',
        element: <Configurations />
      }
    },
    routes: {
      pathBuilder: () => '/routes',
      routeProps: {
        path: '/routes',
        element: <RoutesSummary />
      }
    },
    expeditions: {
      pathBuilder: () => '/expeditions',
      routeProps: {
        path: '/expeditions',
        element: <Expeditions />
      }
    },
    syncRoutes: {
      pathBuilder: () => '/syncRoutes',
      routeProps: {
        path: '/syncRoutes',
        element: <SyncRoutes />
      }
    },
    updateDeliverySalesGroup: {
      pathBuilder: () => '/updateDeliverySalesGroup',
      routeProps: {
        path: '/updateDeliverySalesGroup',
        element: <UpdateDeliverySalesGroup />
      }
    },
    exportRoutes: {
      pathBuilder: () => '/exportRoutes',
      routeProps: {
        path: '/exportRoutes',
        element: <ExportAlternativeRoutes />
      }
    }
  },
  public: {
    login: {
      pathBuilder: () => '/login',
      routeProps: {
        path: '/login',
        element: <Login />
      }
    }
  }
} as const
