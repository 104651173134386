import { DownloadOutlined } from '@ant-design/icons'
import { SalesGroupRoute } from '@wolf/entities'
import { Button, Card, Form, Select, Space } from 'antd'
import fileSaver from 'file-saver'
import { Helmet } from 'react-helmet-async'
import { usePageOutletContext } from '../../../page-outlet-context'
import { dayNames } from '../../../utils/day-names'
import { trpc } from '../../../utils/trpc'

const ROUTE_NAME: Record<number, string> = {
  1: 'Seur',
  2: 'DPD'
}

export const ExportAlternativeRoutes: React.FC = () => {
  const { salesGroupFilter, sourceFilter, workspace } = usePageOutletContext()
  const routeName = ROUTE_NAME[sourceFilter.serialId]

  const salesGroupsRoutes = trpc.salesGroups.getSalesGroupsRoutes.useQuery(
    {
      salesGroupId: salesGroupFilter.salesGroupId,
      sourceId: sourceFilter.serialId,
      routeName
    },
    { enabled: salesGroupFilter.salesGroupId > -1, refetchOnWindowFocus: false }
  ).data

  const generateAlternativeRouteFileMutation = trpc.logistics.exportAlternativeRoute.useMutation({
    onSuccess: (data) => {
      const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'

      const file = `${mediaType}${data.buffer}`

      fileSaver.saveAs(file, data.name)
    }
  })

  const onFinish = async (values: { route: string }) => {
    const { route, day } = JSON.parse(values.route)
    generateAlternativeRouteFileMutation.mutate({
      sourceId: sourceFilter.serialId,
      workspaceId: workspace.serialId,
      salesGroupId: salesGroupFilter.salesGroupId,
      route: {
        day,
        route
      }
    })
  }

  return (
    <>
      <Helmet title={`Exportar rutas ${routeName} - Wolf`} />

      <Card title={`Exportar rutas ${routeName}`} className="mb-4">
        <Form layout="inline" onFinish={onFinish}>
          <Form.Item
            name="route"
            rules={[
              { required: true, message: 'Por favor introduce una ruta' },
            ]}
          >
            <Select
              style={{ minWidth: '12rem' }}
              showSearch
              allowClear
              options={salesGroupsRoutes && salesGroupsRoutes.map((route: SalesGroupRoute) => (
                { label: `${route.route} - ${dayNames[route.day]}`, value: JSON.stringify(route) }
              ))}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                className="self-center mb-4 ml-auto"
                type="primary"
                htmlType="submit"
                icon={<DownloadOutlined />}
              />
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}
